import React from "react"
import * as THREE from "three"
import tw from "tailwind.macro"

const ShapeContainer = tw.div`
    inline-block bg-grey-darkest
`

class RotatingShape extends React.Component {
  componentDidMount() {
    var scene = new THREE.Scene()
    scene.background = new THREE.Color(0x3d4852)
    var camera = new THREE.PerspectiveCamera(
      75,
      window.innerWidth / window.innerHeight ,
      0.1,
      1000
    )
    var renderer = new THREE.WebGLRenderer()
    renderer.setSize(window.innerWidth * .4, window.innerHeight * .4)
    // document.body.appendChild( renderer.domElement );
    // use ref as a mount point of the Three.js scene instead of the document.body
    this.mount.appendChild(renderer.domElement)
    var geometry = new THREE.BoxGeometry(3, 3, 3)
    var edges = new THREE.EdgesGeometry(geometry)
    var line = new THREE.LineSegments(
      edges,
      new THREE.LineBasicMaterial({ color: 0x00a300 })
    )
    var line2 = new THREE.LineSegments(
      edges,
      new THREE.LineBasicMaterial({ color: 0x00a300 })
    )
    var material = new THREE.MeshBasicMaterial({ color: 0xBDFFBD })
    var cube = new THREE.Mesh(geometry, material)
    scene.add(line)
    scene.add(line2)
    scene.add(cube)

    camera.position.z = 5
    var animate = function() {
      requestAnimationFrame(animate)
      line2.rotation.x += -0.01
      line2.rotation.y += -0.01
      line.rotation.x += 0.01
      line.rotation.y += 0.01
      cube.rotation.x += -0.01
      cube.rotation.y += -0.01
      renderer.render(scene, camera)
    }
    animate()

  }
  render() {
    return (
      <ShapeContainer ref={ref => (this.mount = ref)} />
    )
  }
}
export default RotatingShape;
