import tw from "tailwind.macro"
import React from "react"
import SEO from "../components/seo"
import { Link } from "gatsby"
import RotatingShape from "../components/rotatingShape"

const Wrapper = tw.div`
  flex bg-grey-darkest items-center justify-start flex-col h-screen
`

const Main = tw.div`
  p-12 bg-grey-darkest w-screen rounded-lg
`

const Heading = tw.h1`
  text-xl text-blue-500
`

const Text = tw.p`
  text-sm text-black-700
`

const Footer = tw.footer`
  mt-6 text-center
`

export default () => (
  <Wrapper>
    <SEO title="Home" />
    <Main>
      <Link to="/projects" > <Text>projects</Text> </Link>
      <Heading>jdn</Heading>
      <div>
      <RotatingShape />
      </div>
      <Footer />
    </Main>
  </Wrapper>
)
